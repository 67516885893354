<template>
  <div
    class="overflow-hidden relative-position"
    :style="`height: ${height}px; transition: height 0.3s ease;`"
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
  >
    <swiper-component
      v-if="project.media.length"
      :active="isCurrentProject"
      :hover="isHovering"
      :project="project"
      :new-time="videoNewTime"
      :slides="project.media"
      :skip-back="videoSkipBack"
      :skip-forward="videoSkipForward"
      :width="width"
      @init="(slide) => onInit(slide)"
      @loaded="(player) => onLoaded(player)"
      @slide-change="(slide) => onSlideChange(slide)"
      @updated="(player) => onUpdated(player)"
    />
    <div
      v-if="isCurrentProject && isVideoType"
      :class="$q.screen.lt.md ? 'q-pb-sm' : 'q-pb-md'"
      class="absolute-bottom flex flex-center"
      style="z-index: 1"
    >
      <video-player
        :duration="videoDuration"
        :hover="isHovering"
        :project="project"
        :time="videoCurrentTime"
        @change="(value) => onChange(value)"
      />
    </div>
    <router-link
      v-if="
        project?.slug?.current &&
        project?.media[0]?.slug?.current &&
        !isCurrentProject
      "
      :to="{
        name: 'projectSlide',
        params: {
          project: project.slug.current,
          slide:
            swiperCurrentSlides[project.slug.current] ||
            project.media[0].slug.current,
        },
      }"
    >
      <div
        class="absolute-full items-center justify-start row"
        style="z-index: 1"
      >
        <span
          :class="isHovering ? 'bg-primary text-dark' : 'bg-dark text-white'"
          class="q-ml-sm q-px-sm q-py-xs text-caption"
          >{{ project.title }}</span
        >
      </div>
    </router-link>
    <div
      v-if="isCurrentProject"
      class="absolute-top-left q-ma-sm"
      style="z-index: 1"
    >
      <span class="bg-primary text-dark q-px-sm q-py-xs text-caption">
        {{ slideTitle }}</span
      >
    </div>
    <div
      v-if="isCurrentProject"
      class="absolute-top-right q-ma-sm"
      style="z-index: 1"
    >
      <div class="backdrop-blur backdrop-dimmed">
        <q-btn
          dense
          :icon="symSharpClose"
          :size="$q.screen.gt.sm ? 'md' : 'sm'"
          square
          :to="{ path: '/' }"
          unelevated
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { symSharpClose } from "@quasar/extras/material-symbols-sharp";
import { useProjectsStore } from "src/stores/projects";
import { useSwiperStore } from "src/stores/swiper";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";
import VideoPlayer from "src/components/video/VideoPlayer.vue";

defineOptions({ name: "ProjectComponent" });

const props = defineProps({
  project: { type: Object, default: () => {} },
  width: { type: Number, default: 320 },
});

const router = useRouter();
const projectsStore = useProjectsStore();
const { currentProject } = storeToRefs(projectsStore);
const swiperStore = useSwiperStore();
const { swiperCurrentSlides } = storeToRefs(swiperStore);
const isHovering = ref(false);
const isVideoType = ref(false);
const slideTitle = ref("");
const videoCurrentTime = ref(0);
const videoNewTime = ref(0);
const videoDuration = ref(0);
const videoSkipBack = ref(false);
const videoSkipForward = ref(false);
const isCurrentProject = computed(
  () => props.project?.slug?.current === currentProject?.value?.slug?.current
);
const height = computed(() =>
  isCurrentProject.value ? props.width / 2 / props.project.ratio : 82
);

const onChange = (value) => {
  videoNewTime.value = value;
};

const onInit = (slide) => {
  if (!isCurrentProject.value) return;
  if (slide?.type) isVideoType.value = slide.type === "video";
  slideTitle.value = slide.title;
};

const onLoaded = (player) => {
  videoDuration.value = player.duration();
};

const onUpdated = (player) => {
  videoCurrentTime.value = player.currentTime();
};

const onMouseEnter = () => {
  isHovering.value = true;
};

const onMouseLeave = () => {
  isHovering.value = false;
};

const onSkipBack = () => {
  videoSkipBack.value = !videoSkipBack.value;
};

const onSkipForward = () => {
  videoSkipForward.value = !videoSkipForward.value;
};

const onSlideChange = (slide) => {
  isVideoType.value = slide.type === "video";
  if (!isCurrentProject.value) return;

  isVideoType.value = slide.type === "video";
  slideTitle.value = slide.title;
  router.push({
    name: "projectSlide",
    params: {
      project: props.project.slug.current,
      slide: slide.slug.current,
    },
  });
};
</script>
