<template>
  <q-page class="column full-height">
    <template v-for="(project, index) in projects" :key="project._id">
      <project-component
        :id="project.slug.current"
        :project="project"
        :width="width"
      />
      <q-separator v-if="index <= projects.length - 1" />
    </template>
    <q-resize-observer @resize="(size) => onResize(size)" />
  </q-page>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useEventListener } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { scroll, useTimeout } from "quasar";
import { useProjectsStore } from "src/stores/projects";
import { useSwiperStore } from "src/stores/swiper";
import ProjectComponent from "src/components/project/ProjectComponent.vue";

defineOptions({
  name: "IndexPage",
  async preFetch({ store, currentRoute }) {
    const slugParam = currentRoute.params?.project || null;
    const projectsStore = useProjectsStore(store);
    projectsStore.slug = slugParam;
  },
});

const { getScrollTarget, setVerticalScrollPosition } = scroll;
const { registerTimeout } = useTimeout();
const router = useRouter();
const projectsStore = useProjectsStore();
const { currentProject, nextProject, prevProject, slug, projects } =
  storeToRefs(projectsStore);
const swiperStore = useSwiperStore();
const { swiperCurrentSlides } = storeToRefs(swiperStore);
const projectRefs = ref({});
const width = ref(320);

const slugParam = computed(
  () => router.currentRoute.value.params.project || null
);

const collectRefs = () => {
  projects.value.forEach((project) => {
    const el = projectRefs.value[project.slug.current];
    if (el) {
    }
  });
};

const onResize = (size) => {
  width.value = size.width * 2;
};

const scrollToElement = (id) => {
  const el = document.getElementById(id);
  if (el) {
    const target = getScrollTarget(el);
    const offset = el.offsetTop;
    const duration = 400;
    setVerticalScrollPosition(target, offset, duration);
  }
};

const toPrevProject = () => {
  router.push({
    name: "projectSlide",
    params: {
      project: prevProject.value.slug.current,
      slide: swiperCurrentSlides.value[prevProject.value.slug.current],
    },
  });
};

const toNextProject = () => {
  router.push({
    name: "projectSlide",
    params: {
      project: nextProject.value.slug.current,
      slide: swiperCurrentSlides.value[nextProject.value.slug.current],
    },
  });
};

onMounted(async () => {
  useEventListener(document, "keydown", (e) => {
    if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return;
    e.key === "ArrowUp" ? toPrevProject() : toNextProject();
  });
  await nextTick();
  collectRefs();
  if (slug.value) scrollToElement(slug.value);
});

watch(slugParam, (newSlugParam) => {
  slug.value = newSlugParam;
  registerTimeout(() => {
    scrollToElement(slug.value);
  }, 300);
});
</script>
